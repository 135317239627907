import { Member, RegionData, Sectors, Years } from '../interfaces';
/* eslint-disable complexity */
interface FilterTypes {
  value?: string;
  members: Member[];
}

interface CheckedTypes {
  members: Member[];
  sectors: Sectors[];
  checkedSectorFilters: boolean[];
  checkedItems: string[];
}

export const getIndustryCount = ({ value, members }: FilterTypes) => members?.filter(node => {
  if (typeof (node.industry) === 'string') {
    return node.industry === value;
  }

  return (node.industry.find(industry => industry === value));
}).length;

export const getSectorCount = ({ value, members }: FilterTypes) => members?.filter(node => {
  if (typeof (node.sector) === 'string') {
    return node.sector.toLowerCase() === value?.toLowerCase();
  }

  return (node.sector.find(sector => sector.toLowerCase() === value?.toLowerCase()));
}).length;

export const getRegionCount = ({ value, members }: FilterTypes) => members?.filter(node => node.region === value).length;

export const filterSearchTerms = ({ value, members }: FilterTypes) => members.filter(node => {
  const { fullName, city,
    company,
    state,
    country,
    title,
    relatedAwards,
    additional, currentCompany } = node;

  const fullState = statesAndAbbreviations.find(item => state === item.abbreviation)?.name;
  const additionalArray = additional?.trim().split(',');

  if (value === '') {
    return node;
  }

  return value && (
    fullName.toLowerCase().includes(value.toLowerCase()) ||
    city && city.toLowerCase().includes(value.toLowerCase()) ||
    state && state.toLowerCase().includes(value.toLowerCase()) ||
    fullState?.toLowerCase().includes(value.toLowerCase()) ||
    country.toLowerCase().includes(value.toLowerCase()) ||
    title.toLowerCase().includes(value.toLowerCase()) ||
    company.toLowerCase().includes(value.toLowerCase()) ||
    currentCompany?.toLowerCase().includes(value.toLowerCase()) ||
    relatedAwards?.find(award => award.toLowerCase().includes(value.toLowerCase())) ||
    additionalArray?.find(item => item.toLowerCase().includes(value.toLowerCase())));
});

export const filterByYear = ({ value, members }: FilterTypes) => {
  let filteredMembers: Member[] = members;

  if (value === '') {
    filteredMembers = members;
  }
  else {
    filteredMembers = members.filter(node => node.memberYear.toString() === value?.toString());
  }

  return filteredMembers;
};

export const filterCheckedValues = ({ members, checkedSectorFilters, checkedItems, sectors }: CheckedTypes) => {
  const selectedSectors = sectors.filter((node: Sectors, idx: number) => checkedSectorFilters[idx]).map((node => node.sectorTitle));

  return members.filter((member: Member) => {
    const { sector } = member;
    // Check sector filters
    const sectorFilterPassed = selectedSectors.length === 0 || selectedSectors?.some((sec) => sector.includes(sec));

    // Check region filters
    const checkRegionCountry = () => {
      if (checkedItems.includes(member.region)) {
        return checkedItems.includes(member.country);
      }

      return checkedItems.includes(member.region) || checkedItems.includes(member.country);
    };
    const regionFilterPassed = checkedItems.length === 0 ||
    checkRegionCountry();

    return sectorFilterPassed && regionFilterPassed;
  });
};

export const getSelectedMember = ({ value, members }: FilterTypes) => members.filter(node => node.id === value);

export const getMaxPages = ({ amount, itemsPerPage }: { amount: number, itemsPerPage: number }) =>
  (amount > 0 ? Math.ceil(amount / itemsPerPage) : 1);

export const getPaginationBounds = ({ currentPage, itemsPerPage, idx }: { currentPage: number, itemsPerPage: number, idx: number }) => {
  const min = (Number(currentPage) - 1) * itemsPerPage;
  const max = (Number(currentPage) * itemsPerPage);

  return idx >= min && idx < max;
};

export const statesAndAbbreviations = [
  { name: 'ALABAMA', abbreviation: 'AL' },
  { name: 'ALASKA', abbreviation: 'AK' },
  { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
  { name: 'ARIZONA', abbreviation: 'AZ' },
  { name: 'ARKANSAS', abbreviation: 'AR' },
  { name: 'CALIFORNIA', abbreviation: 'CA' },
  { name: 'COLORADO', abbreviation: 'CO' },
  { name: 'CONNECTICUT', abbreviation: 'CT' },
  { name: 'DELAWARE', abbreviation: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
  { name: 'FLORIDA', abbreviation: 'FL' },
  { name: 'GEORGIA', abbreviation: 'GA' },
  { name: 'GUAM', abbreviation: 'GU' },
  { name: 'HAWAII', abbreviation: 'HI' },
  { name: 'IDAHO', abbreviation: 'ID' },
  { name: 'ILLINOIS', abbreviation: 'IL' },
  { name: 'INDIANA', abbreviation: 'IN' },
  { name: 'IOWA', abbreviation: 'IA' },
  { name: 'KANSAS', abbreviation: 'KS' },
  { name: 'KENTUCKY', abbreviation: 'KY' },
  { name: 'LOUISIANA', abbreviation: 'LA' },
  { name: 'MAINE', abbreviation: 'ME' },
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
  { name: 'MARYLAND', abbreviation: 'MD' },
  { name: 'MASSACHUSETTS', abbreviation: 'MA' },
  { name: 'MICHIGAN', abbreviation: 'MI' },
  { name: 'MINNESOTA', abbreviation: 'MN' },
  { name: 'MISSISSIPPI', abbreviation: 'MS' },
  { name: 'MISSOURI', abbreviation: 'MO' },
  { name: 'MONTANA', abbreviation: 'MT' },
  { name: 'NEBRASKA', abbreviation: 'NE' },
  { name: 'NEVADA', abbreviation: 'NV' },
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
  { name: 'NEW JERSEY', abbreviation: 'NJ' },
  { name: 'NEW MEXICO', abbreviation: 'NM' },
  { name: 'NEW YORK', abbreviation: 'NY' },
  { name: 'NORTH CAROLINA', abbreviation: 'NC' },
  { name: 'NORTH DAKOTA', abbreviation: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
  { name: 'OHIO', abbreviation: 'OH' },
  { name: 'OKLAHOMA', abbreviation: 'OK' },
  { name: 'OREGON', abbreviation: 'OR' },
  { name: 'PALAU', abbreviation: 'PW' },
  { name: 'PENNSYLVANIA', abbreviation: 'PA' },
  { name: 'PUERTO RICO', abbreviation: 'PR' },
  { name: 'RHODE ISLAND', abbreviation: 'RI' },
  { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
  { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
  { name: 'TENNESSEE', abbreviation: 'TN' },
  { name: 'TEXAS', abbreviation: 'TX' },
  { name: 'UTAH', abbreviation: 'UT' },
  { name: 'VERMONT', abbreviation: 'VT' },
  { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
  { name: 'VIRGINIA', abbreviation: 'VA' },
  { name: 'WASHINGTON', abbreviation: 'WA' },
  { name: 'WEST VIRGINIA', abbreviation: 'WV' },
  { name: 'WISCONSIN', abbreviation: 'WI' },
  { name: 'WYOMING', abbreviation: 'WY' },
  { name: 'Alberta', abbreviation: 'AB' },
  { name: 'British Columbia', abbreviation: 'BC' },
  { name: 'Manitoba', abbreviation: 'MB' },
  { name: 'New Brunswick', abbreviation: 'NB' },
  { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
  { name: 'Northwest Territories', abbreviation: 'NT' },
  { name: 'Nova Scotia', abbreviation: 'NS' },
  { name: 'Nunavut', abbreviation: 'NU' },
  { name: 'Ontario', abbreviation: 'ON' },
  { name: 'Prince Edward Island', abbreviation: 'PE' },
  { name: 'Quebec', abbreviation: 'QC' },
  { name: 'Saskatchewan', abbreviation: 'SK' },
  { name: 'Yukon Territory', abbreviation: 'YT' }
];


export const transformIds = (members: Member[]) => members.map((member: Member) => ({
  ...member,
  id: `${member.memberYear}-${member.id.replace('files__', '')}`
}));

export const copyToClipboard = (text: string, callback: () => void) => {
  navigator.clipboard.writeText(text).then(() => {
    callback();
  });
};

export const reverseYearOrder = (data: Years[]): Years[] => {
  const reversedYears = data.sort((p1, p2) => {
    if (p1.year < p2.year) {
      return 1;
    }

    return (p1.year > p2.year ? -1 : 0);
  });

  return reversedYears;
};

export const filterCountriesByRegions = (members: Member[]): RegionData[] => {
  const regions: Record<string, RegionData[]> = members.reduce((data, member) => {
    const { region, country } = member;
    if (region && country) {
      data[region] = data[region] || [];

      const existingCountry = data[region].find((countr) => countr.key === country);
      if (!existingCountry) {
        data[region].push({
          title: country,
          key: country,
          children: []
        });
      }
    }

    return data;
  }, {} as Record<string, RegionData[]>);

  const sortedRegions = Object.entries(regions).map(([region, countries]) => ({
    title: region,
    key: region,
    children: countries.sort((country1, country2) => country1.title.localeCompare(country2.title))
  }));

  return sortedRegions.sort((region1, region2) => region1.title.localeCompare(region2.title));
};

export const sortedByName = (members: Member[], filteredData: Member[]) => {
  const sortedData = [...filteredData].sort((a, b) => a.fullName.localeCompare(b.fullName));
  members.sort((a, b) => a.fullName.localeCompare(b.fullName));

  return sortedData;
};

export const sortedByCountry = (members: Member[], filteredData: Member[]) => {
  const sortedData = [...filteredData].sort((a, b) => a.country.localeCompare(b.country));
  members.sort((a, b) => a.country.localeCompare(b.country));

  return sortedData;
};

export const regions = [
  { region: 'Americas' },
  { region: 'EMEIA' },
  { region: 'Africa' },
  { region: 'Asia-Pacific' },
  { region: 'Asia' },
  { region: 'Australia/Oceania' },
  { region: 'Europe' },
  { region: 'India' },
  { region: 'Middle East' },
  { region: 'North America' },
  { region: 'South America' }
];
