import MotifTheme from '@ey-xd/motif-react/components/theme';
import React from 'react';
import './index.scss';
import CookieTableComponent from '../components/cookieTable';
import { SiteCookies } from '../interfaces';
import { useNavigate } from 'react-router-dom';

interface CookieSettingsProps {
  data: SiteCookies;
}

const CookieSettings: React.FC<CookieSettingsProps> = ({ data }) => {
  const siteCookies = data;
  const navigate = useNavigate();

  const acceptCookies = () => {
    const analyticsCheckbox = document.getElementById('google-analytics') as HTMLInputElement;
    const adwordsCheckbox = document.getElementById('google-adwords') as HTMLInputElement;
    localStorage.acceptGoogleAnalytics = analyticsCheckbox?.checked;
    localStorage.acceptGoogleAdwords = adwordsCheckbox?.checked;
    localStorage.cookieAccepted = true;
    navigate(`/`);
  };

  const rejectCookies = () => {
    localStorage.removeItem('acceptGoogleAnalytics');
    localStorage.removeItem('acceptGoogleAdwords');
    localStorage.removeItem('cookieAccepted');
    navigate(`/`);
  };

  return (
    <>
      <MotifTheme />
      {/* BODY */}
      <div className="eoy-cookie-wrapper" data-testid="cookie-settings">
        <CookieTableComponent data={siteCookies} accept={acceptCookies} decline={rejectCookies} />
      </div>
    </>
  );
};

export default CookieSettings;

