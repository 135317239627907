import React from 'react';
import { Member } from '../../interfaces';
import CopyToClipboard from '../clipboard';
import './index.scss';
interface ModalContentTypes {
  data: Member;
}

const ModalContent: React.FC<ModalContentTypes> = ({ data }) => {
  const { title, company, fullName, city,
    state, country, headshot, bioContent, sector, companyWebsite, memberYear, relatedAwards, id, currentCompany } = data;
  const removeHttp = () => (companyWebsite ? companyWebsite.replace(/^https?:\/\//, '') : null);
  const getIndustries = (value: string | string[]) => (typeof (value) === 'string' ?
    <p>{value}</p> :
    <p>{value.map((indus: string, index: number) => (index < (value.length - 1) ? `${indus}, ` : indus))}</p>);

  return (
    <div className="eoy-modal">
      <div className="eoy-modal-body">
        <div
          className="eoy-modal-avatar"
          role="img"
          aria-label={headshot ? 'profile' : 'placeholder'}
          style={{ backgroundImage: `url(${headshot ? headshot : '../../assets/images/upload/headshot-placeholder.png'}` }} />
        <div className="eoy-modal-personal-details">
          <h3 className="eoy-modal-fullname">{fullName}</h3>
          <p className="eoy-modal-content eoy-modal-content-title">{title}</p>
          <p className="eoy-modal-content">{company}</p>
          <p className="eoy-modal-content">{city ? `${city}, ` : ''}{state ? `${state}, ` : ``}{country}</p>
          <CopyToClipboard id={id} />
        </div>
      </div>
      <hr />
      <div className="eoy-modal-description-wrapper">
        <div className="eoy-modal-description">
          <p>{bioContent}</p>
        </div>
        <div className="eoy-modal-business-details">
          {memberYear && <p className="eoy-web-memberyear">EOY program year {memberYear}</p>}
          <div>{getIndustries(sector)}</div>
          {removeHttp() && <p className="eoy-web-copy">{removeHttp()}</p> }
          {relatedAwards && <p className="eoy-web-additional">{relatedAwards.map((award, idx) => (idx === 0 ? award : `, ${award}`))}</p>}
          {currentCompany && <p className="eoy-web-currentcompany">Current Company: {currentCompany}</p> }

        </div>
      </div>
    </div>
  );
};

export default ModalContent;
