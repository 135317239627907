import { MotifButton, MotifModal, MotifModalBody, MotifModalFooter, MotifModalHeader } from '@ey-xd/motif-react';
import React from 'react';
import FocusLock from 'react-focus-lock';
import { Member } from '../../interfaces';
import ModalContent from '../modalContent';
import LinkedInLogo from '../../assets/images/linkedIn-logo.svg';
import './index.scss';

const closeButtonProps = {
  title: 'Close modal button',
  'aria-label': 'Close modal button'
};

interface ModalWrapperTypes {
    data: Member;
    handleClose: () => void;
    modalVisible: boolean;
}

const ModalWrapper: React.FC<ModalWrapperTypes> = ({
  data, handleClose, modalVisible
}: ModalWrapperTypes) => {
  const modalData = data;
  const { linkedIn, companyWebsite } = modalData;

  return (
    <>
      <MotifModal
        show={modalVisible}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: 'none'
          }
        }}
        onClose={() => handleClose()} className="wwna-modal"
        aria-labelledby="dialog_label">

        <FocusLock className="eoy-modal-focus-wrapper">
          <MotifModalHeader closeButtonProps={closeButtonProps} id="dialog_label">Profile Information</MotifModalHeader>
          <MotifModalBody><ModalContent data={modalData} /></MotifModalBody>
          <MotifModalFooter>
            {linkedIn &&
        <a className="eoy-modal-btn-wrapper" href={linkedIn} target="_blank" rel="noopener noreferrer">
          <MotifButton
            size="large"
            type="button"
            variant="primary-alt">
            Connect on LinkedIn
            <img className="eoy-linkedin-icon" src={LinkedInLogo} alt="linkedIn-logo" />
          </MotifButton>
        </a>}
            {companyWebsite &&
        <a className="eoy-modal-btn-wrapper" href={companyWebsite} target="_blank" rel="noopener noreferrer">
          <MotifButton
            size="large"
            variant="secondary"
            type="button"
          >
              Visit Website
          </MotifButton>
        </a>
            }
          </MotifModalFooter>
        </FocusLock>
        <button
          className="eoy-modal-catcher eoy-reset-button"
          onClick={() => { handleClose(); }}
          data-testid="catcher"
          onKeyDown={(event) => { if (event.key === 'Escape') {
            handleClose();
          }
          }} />
      </MotifModal>

    </>
  ); };

export default ModalWrapper;
