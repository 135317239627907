import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import membersJSON from './data/members.json';
import sectorJSON from './data/sector.json';
import themeJSON from './data/theme.json';
import yearsJSON from './data/years.json';
import { PageContext, SiteContent, SiteCookies, Years } from './interfaces';
import IndexPage from './templates';
import PageNotFound from './templates/404';
import { reverseYearOrder, transformIds } from './utils';
import CookieSettings from './templates/cookie';

const yearsSorted:Years[] = reverseYearOrder(yearsJSON.data);

// const siteInfo: SiteContent = themeJSON.data.find(data => data.id === 'files__info');

const pageData: PageContext = {
  members: transformIds(membersJSON.data),
  years: yearsSorted,
  siteContent: themeJSON.data[1],
  siteCookies: themeJSON.data[0] as SiteCookies,
  sectors: sectorJSON.data
};


const router = createBrowserRouter([
  {
    path: '/',
    element: <IndexPage pageData={pageData} />
  },
  { path: '/member/:memberId',
    element: <IndexPage pageData={pageData} /> },
  { path: '*',
    element: <PageNotFound data={pageData.siteContent as SiteContent} /> },
  { path: '/cookies',
    element: <CookieSettings data={pageData.siteCookies as SiteCookies} /> }
], {
  basename: process.env.PUBLIC_URL
});

const App: React.FC = () => (
  <div className="eoy-app-root">
    <HelmetProvider context={{}}>
      <RouterProvider router={router} />
    </HelmetProvider>
  </div>
);

export default App;
