import {
  MotifButton,
  MotifHeader,
  MotifHeaderLogo,
  MotifTheme
} from '@ey-xd/motif-react';
import React from 'react';
import EYLogo from '../assets/images/ey-logo.svg';
import FooterComponent from '../components/footer';
import MetaTags from '../components/meta';
import { SiteContent } from '../interfaces';
import './index.scss';

const eyUrl = 'https://www.ey.com';

interface PageNotFoundProps {
    data: SiteContent;
  }

const PageNotFound: React.FC<PageNotFoundProps> = ({ data }) => {
  const {
    siteTitle,
    footnote,
    footerCTA,
    footerContent,
    footerLink,
    mainMetaTitle,
    mainMetaKeywords,
    mainMetaDescription
  } = data;

  const metadata = { ...data,
    mainMetaTitle: `404 | ${mainMetaTitle}`,
    mainMetaKeywords,
    mainMetaDescription };

  return (
    <>
      <MetaTags
        data={metadata} />
      <MotifTheme />
      {/* HEADER */}
      <MotifHeader
        appHeaderName={siteTitle}
        fixed
        logo={
          <MotifHeaderLogo>
            <a href={eyUrl}>
              <img src={EYLogo} aria-hidden="true" alt="ey logo" />
              <span className="hide-for-accessibility">EY Logo - Home</span>
            </a>
          </MotifHeaderLogo>
        }
      />
      {/* BODY */}
      <div className="motif-row eoy-404-wrapper">
        <div className="motif-col-xs-12 motif-center-xs eoy-404-content">
          <h1 className="eoy-404-header">Page Not Found</h1>
          <p>Please visit one of our other sites:</p>
          <a href="/" className="eoy-404-link">
            <MotifButton
              size="large"
              variant="secondary"
              className="eoy-404-button">Return to eoy Directory</MotifButton>
          </a>
          <a href={footerLink} className="eoy-404-link">
            <MotifButton
              size="large"
              variant="secondary"
              className="eoy-404-button">eoy Website</MotifButton>
          </a>
          <a href={eyUrl} className="eoy-404-link">
            <MotifButton
              size="large"
              variant="secondary"
              className="eoy-404-button">EY.com</MotifButton>
          </a>
        </div>
      </div>
      {/* FOOTER */}
      <FooterComponent
        footerCTA={footerCTA}
        footerLink={footerLink}
        footerContent={footerContent}
        footnote={footnote}
        full={false} />

    </>
  );
};

export default PageNotFound;

