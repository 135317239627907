import { MotifCheckbox, MotifTreeNode } from '@ey-xd/motif-react';
import React, { useEffect, useState } from 'react';
import { RegionData } from '../../interfaces';
import './index.scss';

interface TreePropTypes {
  item: RegionData;
  checkedItems: string[];
  onChecked: (key: string, checked: boolean) => void;
  currentlyOpenItem: string | null;
  onItemClick: (key: string) => void;
}

const TreeComponent: React.FC<TreePropTypes> = ({ item, checkedItems, onChecked, currentlyOpenItem,
  onItemClick }) => {
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    // Update the component state when checkedItems change
    setIsChecked(checkedItems.includes(item.key));
    if (item.children && item.children.length > 0) {
      const allChildrenChecked = item.children.every((child: RegionData) =>
        checkedItems.includes(child.key)
      );
      const someChildrenChecked = item.children.some((child: RegionData) =>
        checkedItems.includes(child.key)
      );
      setIsIndeterminate(!allChildrenChecked && someChildrenChecked);
      setIsChecked(allChildrenChecked && someChildrenChecked);
    } else {
      setIsIndeterminate(false);
    }
  }, [checkedItems, item]);

  const handleChecked = (key: string, checked: boolean) => {
    onChecked(key, checked);

    if (item.children && item.children.length > 0) {
      // Check/uncheck all children
      item.children.forEach((child: RegionData) => onChecked(child.key, checked));
    }
  };

  const handleNodeClick = () => {
    onItemClick(item.key);
  };

  return (
    <>
      <MotifTreeNode
        className="eoy-tree-node"
        open={currentlyOpenItem === item.key}
        onClick={handleNodeClick}
        value={<MotifCheckbox
          id={item.key}
          tabIndex={0}
          role="checkbox"
          value={item.key}
          name={item.key}
          aria-checked={isChecked}
          indeterminate={isIndeterminate}
          checked={isChecked}
          onChange={() => {
            handleChecked(item.key, !isChecked);
          }}
        >
          {item.title}
        </MotifCheckbox>}>
        {item.children && item.children.length > 0 && item.children.map((country: RegionData) => (
          <TreeComponent
            item={country} key={country.key}
            checkedItems={checkedItems} onChecked={onChecked}
            currentlyOpenItem={currentlyOpenItem}
            onItemClick={onItemClick} />
        ))}
      </MotifTreeNode>
    </>
  );
};

export default TreeComponent;
