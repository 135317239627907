import { MotifButton } from '@ey-xd/motif-react';
import './index.scss';
import React from 'react';
import { CookieData, SiteCookies } from '../../interfaces';

interface CookieSettingsPropTypes {
  data: SiteCookies;
  accept: () => void;
  decline: () => void;
}

const CookieTableComponent: React.FC<CookieSettingsPropTypes> = ({ data, accept, decline }) => {
  const { headings, buttons, cookies } = data;


  return (
    <>
      <article className="eoy-cookie-content">
        <h1 className="eoy-title">Cookie Settings</h1>
        <div className="cookie-settings">
          <div className="cookie-settings-heading">
            <ul className="cookie-settings-heading-list">
              <li className="cookie-settings-heading-item">{headings.provider}</li>
              <li className="cookie-settings-heading-item">{headings.name}</li>
              <li className="cookie-settings-heading-item">{headings.purpose}</li>
              <li className="cookie-settings-heading-item">{headings.type}</li>
              <li className="cookie-settings-heading-item">{headings.duration}</li>
              <li className="cookie-settings-heading-item cookie-settings-spacer" />
              <li className="cookie-settings-heading-item">{headings.accept}</li>
            </ul>
          </div>
          <ul className="cookie-settings-list">
            {cookies.map((cookie: CookieData) => (
              <>
                <li className="cookie-settings-item cookie-settings-google-analytics">
                  <ul className="cookie-settings-detail-list">
                    <li className="cookie-settings-detail-item">
                      <div className="cookie-settings-detail-label">Provider:</div>
                      <div className="cookie-settings-detail-value">{cookie.provider}</div>
                    </li>
                    <li className="cookie-settings-detail-item">
                      <div className="cookie-settings-detail-label">Name:</div>
                      <div className="cookie-settings-detail-value">{cookie.name}</div>
                    </li>
                    <li className="cookie-settings-detail-item">
                      <div className="cookie-settings-detail-label">Purpose:</div>
                      <div className="cookie-settings-detail-value">{cookie.purpose}</div>
                    </li>
                    <li className="cookie-settings-detail-item">
                      <div className="cookie-settings-detail-label">Type:</div>
                      <div className="cookie-settings-detail-value">{cookie.type}</div>
                    </li>
                    <li className="cookie-settings-detail-item">
                      <div className="cookie-settings-detail-label">Duration:</div>
                      <div className="cookie-settings-detail-value">{cookie.duration}</div>
                    </li>
                    <li className="cookie-settings-detail-item cookie-settings-spacer" />
                    <li className="cookie-settings-detail-item">
                      {cookie.accept_label &&
                        <>
                          <input
                            className="cookie-settings-detail-accept"
                            type="checkbox"
                            id={cookie.provider.toLowerCase().replace(/\s/g, '-')} />
                          <label className="cookie-settings-label">{cookie.accept_label}</label>
                        </>}
                    </li>
                  </ul>
                </li>
              </>
            )
            )}
          </ul>
        </div>
        <section className="ey-cookie-notification">
          <MotifButton
            size="large"
            className="eoy-footer-button" onClick={accept}>{buttons.accept}</MotifButton>

          <MotifButton
            size="large"
            className="eoy-footer-button" onClick={decline}>{buttons.decline}</MotifButton>
        </section>
      </article>
    </>
  );
};

export default CookieTableComponent;
