import { MotifCard, MotifCardBody } from '@ey-xd/motif-react';
import React from 'react';
import { Member } from '../../interfaces';
import './index.scss';

interface CardProps {
   data: Member;
   onClick: () => void;
}

const CardComponent: React.FC<CardProps> = ({ data, onClick }) => {
  const { title, company, fullName, city, state, country, headshot } = data;

  return (<div
    className="motif-col-xs-4 motif-col-sm-4 motif-col-md-4 motif-col-lg-6 eoy-card-wrapper"
  >
    <button onClick={onClick} className="eoy-reset-button eoy-card-button">
      <MotifCard className="eoy-card">
        <MotifCardBody className="eoy-card-body">
          <div
            className="eoy-card-avatar"
            role="img"
            aria-label={headshot ? 'profile' : 'placeholder'}
            style={{ backgroundImage: `url(${headshot ? headshot : '../../assets/images/upload/headshot-placeholder.png'}` }} />
          <div className="eoy-card-description">
            <h2 className="eoy-card-content eoy-card-content-name">{fullName}</h2>
            <p className="eoy-card-content eoy-card-content-title">{title}</p>
            <p className="eoy-card-content">{company}</p>
            <p className="eoy-card-content">{city ? `${city}, ` : ''}{state ? `${state}, ` : ``}{country}</p>
          </div>

        </MotifCardBody>
      </MotifCard>
    </button>
  </div>);
};


export default CardComponent;
