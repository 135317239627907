import { MotifCheckbox, MotifFormField } from '@ey-xd/motif-react';
import React from 'react';
import { FilterOptions, Member } from '../../interfaces';
import { getSectorCount, getRegionCount } from '../../utils';
import './index.scss';

export interface CheckboxProps {
  checkedFilters: boolean[];
  onChange: (idx: number) => void;
  members: Member[];
  filters: FilterOptions[];
  className?: string | null;
  type: string
}

const CheckboxFilterComponent: React.FC<CheckboxProps> = ({ checkedFilters,
  onChange,
  members,
  filters,
  className,
  type }) => <>
  {filters.map((node, idx: number) => {
    const title: string = node.sectorTitle || node.region || '';
    const count: number = node.sectorTitle ? getSectorCount({ value: title, members }) : getRegionCount({ value: title, members });
    const id = `${title.toLowerCase().replaceAll(' ', '-')}-${type}`;

    return (count > 0 && <MotifFormField key={idx}>
      <MotifCheckbox
        tabIndex={0}
        role="checkbox"
        value={title}
        name={title}
        checked={checkedFilters[idx]}
        id={id}
        aria-checked={checkedFilters[idx]}
        onChange={() => { onChange(idx); }}
        className={className ? className : ''}
      >
        {title} {`(${count})`}
      </MotifCheckbox>
    </MotifFormField>);
  })}
</>;

CheckboxFilterComponent.defaultProps = {
  className: null
};
export default CheckboxFilterComponent;
